import { compose, dark, light } from "@aston/themes"

const theme = {
    name: 'Dark',
    id: 'dark',
    colors: {
        main:      '#555',
        primary:   '#226',
        secondary: '#668'
    },
    business: {
        kpisColors: {
            0: compose('#1558A6', light),
            1: compose('#2C6BB0', light),
            2: compose('#447DBA', dark),
            3: compose('#5B90C3', dark),
            4: compose('#73A2CD', dark),
            5: compose('#8AB5D7', dark),
            6: compose('#8EBFD3', dark),
            7: compose('#90C5D1', dark),
            8: compose('#92CACE', dark),
            9: compose('#96D4CA', dark),
            10: compose('#1558A6', light),
            danger: compose('#f3745a', light)
        },
        dunningLevelsColors: {
            STD: compose('#0e417a', light),
            PAA: compose('#4b8d54', dark),
            PPA: compose('#0e5f2c', light),
            LTG: compose('#c08b42', dark),
            CTX: compose('#a7351d', light),
        },  
        dunningActionTypeColors: {
            0: compose('#e36d55', dark),
            1: compose('#edac57', dark),
            2: compose('#7096c0', dark),
            3: compose('#87b39a', dark),
            4: compose('#004b5a', light),
            5: compose('#097c7d', dark),
            6: compose('#d58b03', light),
            7: compose('#cbbc93', dark),
            8: compose('#004454', light),
        }
    }
}
export default theme
