import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { RouterLink } from '@angular/router';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';

import { IBreadcrumb } from '../../models';
import { BreadcrumbService } from '../../services';

import { BreadcrumbItemComponent } from './breadcrumb-item.component';


@Component({
	selector: 'layout-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	standalone: true,
	imports: [NgFor, NgIf, BreadcrumbItemComponent, RouterLink, AsyncPipe]
})
export class BreadcrumbComponent {
	public breadcrumbs: Observable<IBreadcrumb[]> = this.breadcrumbService.breadcrumbs$;

	constructor(private breadcrumbService: BreadcrumbService) { }
}
