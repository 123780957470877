import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

import { IBreadcrumb } from '../../models';


@Component({
	selector: 'layout-breadcrumb-item',
	templateUrl: './breadcrumb-item.component.html',
	standalone: true,
	imports: [NgIf, TranslateModule]
})
export class BreadcrumbItemComponent {
	@Input() item: IBreadcrumb;
}
