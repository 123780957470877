<div id="app" class="authentication-layout">
	<section id="content">
		<div class="app-logo">
			<img src="/assets/images/logos/logo_aston_ai.png" width="200px" alt="{{ 'App.Name' | translate }} - {{ 'App.Baseline' | translate }}">
		</div>

		<main id="main">
			<router-outlet></router-outlet>

		</main>
	</section>
</div>
