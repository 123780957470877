
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Loader, UnsubscriberClass } from '@aston/foundation';
import { TranslateModule } from '@ngx-translate/core';

import { AuthenticationService } from '../../services';

@Component({
	selector: 'authentication-issue-page',
	templateUrl: './authentication-issue-page.component.html',
	standalone: true,
	imports: [TranslateModule]
})
export class AuthenticationIssuePageComponent extends UnsubscriberClass {

	formLoader = new Loader();
	redirectAlreadyDone: boolean;

	constructor(
		protected authenticationService: AuthenticationService,
		protected activatedRoute: ActivatedRoute,
		protected router: Router) {
		super();
	}
}
