import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'authentication-layout',
	templateUrl: './authentication-layout.component.html',
	standalone: true,
	imports: [TranslateModule, RouterOutlet]
})
export class AuthenticationLayoutComponent {
}
