

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderSpinnerComponent, TableRowEmptyComponent, stripSlashes } from '@aston/foundation';
import { delay, map, take } from 'rxjs/operators';
import { AbstractSecurityStorage, AuthModule, LogLevel, OpenIdConfiguration, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { TranslateModule } from '@ngx-translate/core';

import { AppConfiguration } from '../app.configuration';

import { AuthenticationIssuePageComponent } from './pages/authent-issue-page/authentication-issue-page.component';
import { InactivityModalComponent } from './components/inactivity-modal/inactivity-modal.component';
import { AuthenticationGuard, AnonymousGuard } from './guards';
import { AuthenticationInterceptor } from './interceptors';
import { AuthenticationService, AuthenticationStorageService } from './services';
import { AuthenticationLayoutComponent } from './layouts/authentication-layout/authentication-layout.component';
import { LoginCallbackPageComponent } from './pages/callback-page/callback-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';

@NgModule({
	imports: [
		AuthenticationIssuePageComponent,
		AuthenticationLayoutComponent,
		CommonModule,
		FormsModule,
		InactivityModalComponent,
		LoaderSpinnerComponent,
		LoginCallbackPageComponent,
		LoginPageComponent,
		ReactiveFormsModule,
		AuthModule.forRoot({
			loader: {
			  provide: StsConfigLoader,
			  useFactory: configureAuth,
			  deps: [AppConfiguration, AuthenticationStorageService],
			},
		}),
		RouterModule,
		TableRowEmptyComponent,
		TranslateModule,
	],
	providers: [
		AnonymousGuard,
		AuthenticationGuard,
		AuthenticationService,
		AuthenticationStorageService,
		{ provide: AbstractSecurityStorage, useClass: AuthenticationStorageService },
		{provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true}
	],
	exports: [AuthModule],
})
export class AuthenticationModule {
}

const AUTHENTICATION_START_DATE_KEY = 'AUTHENTICATION_START_DATE_KEY';

function setAuthenticationStartDate() {
	localStorage.setItem(AUTHENTICATION_START_DATE_KEY, new Date().toISOString());
}

function getLastAuthenticationStartDate() {
	const dateString = localStorage.getItem(AUTHENTICATION_START_DATE_KEY);
	return new Date(dateString);
}

function delayToWaitBeforeAuthentication(): number {
	// Gate signout an user before letting you sign-in.
	// if you launch 2 MiddleOffice at the same time, you get stuck in a loop
	// where both MOs signout each others
	// so we introduce a 7 seconds delay between 2 authentications.
	// this fix https://dev.azure.com/astonitf/LBP/_workitems/edit/18136
	const lastAuthenticationDate = getLastAuthenticationStartDate().getTime();
	const now = new Date().getTime();
	const deltaMilliseconds = now - lastAuthenticationDate;
	const delayBetween2Authentications = 7 * 1000;
	if (deltaMilliseconds >= delayBetween2Authentications) {
		return 0;
	}
	return delayBetween2Authentications - deltaMilliseconds;
}

// https://github.com/damienbod/angular-auth-oidc-client/blob/master/projects/sample-code-flow-http-config/src/app/app.module.ts
export function configureAuth(config: AppConfiguration, storage: AuthenticationStorageService) {
	const config$ = config.appConfigLoaded$.pipe(
		take(1),
		delay(delayToWaitBeforeAuthentication()),
		map<unknown, OpenIdConfiguration>(_ => {
			const authenticationServerUrl = stripSlashes(config.authenticationServerUrl);
			const baseUrl = `${stripSlashes(window.location.origin)}`;
			const appUrl = `${stripSlashes(baseUrl)}`;

			setAuthenticationStartDate();
			return {
				authority: authenticationServerUrl,
				redirectUrl: `${appUrl}/authentication/callback`,
				clientId: config.clientId,
				responseType: 'code',
				scope: 'openid profile offline_access',
				postLogoutRedirectUri: config.logOffUrl,
				startCheckSession: false, // this seems to work only with a silentRenewUrl
				silentRenew: true,  // a timer is ticked each tokenRefreshInSeconds to check if the token is expired or not
				tokenRefreshInSeconds: 10, // value was increased because the refresh process took more than the default value and
				useRefreshToken: true, // requires offline_access scope
				ignoreNonceAfterRefresh: true, // specific to openIdDict
				renewTimeBeforeTokenExpiresInSeconds: 30,
				maxIdTokenIatOffsetAllowedInSeconds: 60, // offset in second allowed between openid server time and client time
				postLoginRoute: `${baseUrl}`,
				forbiddenRoute: `${baseUrl}`,
				unauthorizedRoute: `${baseUrl}`,
				logLevel: LogLevel.Debug, // events filtering is done in the logger service
				silentRenewTimeoutInSeconds: 30,
				historyCleanupOff: true,
				autoUserInfo: false,
				storage
			};
		}),
	);

	return new StsConfigHttpLoader(config$);
}
