<form class="search-form">
	<div class="input-group">
		<ng-select #selector
			class="ng-select-custom ng-select-custom-alt"
			(blur)="searchInput$.next('')"
			(change)="onSelect($event)"
			[addTag]="false"
			[clearSearchOnAdd]="true"
			[closeOnSelect]="true"
			[groupBy]="searchGroupByFn"
			[groupValue]="searchGroupName"
			[items]="results"
			[loadingText]="'Loaders.Default' | translate"
			[minTermLength]="0"
			[multiple]="false"
			[ngModel]="searchInputModel"
			[ngModelOptions]="{standalone: true}"
			[notFoundText]="'TopBar.Search.NoResults' | translate"
			[placeholder]="'TopBar.Search.Placeholder' | translate"
			[searchable]="true"
			[typeahead]="searchInput$"
			[typeToSearchText]="'TopBar.Search.Placeholder' | translate"
			bindLabel="label"
		>
			<ng-template ng-optgroup-tmp let-item="item">
				<span class="align-middle">{{item.name}}</span>
				<span class="ml-1 badge badge-secondary">{{item.total}}</span>
			</ng-template>
		</ng-select>
	</div>
	<i class="icon-search fal fa-search fa-lg"></i>
</form>
