<div class="notifications-list" [class.block-loader]="loader.isLoading$ | async">
	<loader-spinner [model]="loader"/>

	<div class="notifications-list-scroll">
		@for (item of notifications?.items; track item.id) {
			<notification-item
				[notification]="item"
				(click)="open(item)"
			/>
		}
	</div>
</div>
