import { Directive, HostListener, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { scrollToElement } from '../../functions';


@Directive({
	selector: '[scrollToAnchor]',
	standalone: true
})
export class ScrollToAnchorDirective implements OnInit {
	@Input('scrollToAnchor') target: string;

	constructor(private el: ElementRef, private renderer: Renderer2) {
	}

	ngOnInit() {
		this.renderer.setAttribute(this.el.nativeElement, 'href', this.target);
	}

	@HostListener('click', ['$event']) onClick($event: Event) {
		$event.preventDefault();
		$event.stopPropagation();

		if (this.target) {
			scrollToElement(this.target);
		}
	}
}
