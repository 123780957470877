
import { Component, Input } from '@angular/core';
import { INotificationItem } from 'apps/middle/src/app/shared-module/models';
import { TranslateModule } from '@ngx-translate/core';
import { DateComponent } from '@aston/foundation';

import { NotificationCategory } from '../../enums';
import { NotificationItemAvatarComponent } from '../notification-item-avatar/notification-item-avatar.component';
import { NotificationRelatedEntityPipe, NotificationStatusPipe } from '../../pipes';

@Component({
	selector: 'notification-item',
	templateUrl: './notification-item.component.html',
	standalone: true,
	imports: [TranslateModule, NotificationItemAvatarComponent, NotificationStatusPipe, DateComponent, NotificationRelatedEntityPipe]
})
export class NotificationItemComponent {
	@Input() notification: INotificationItem;

	notificationsCategories = NotificationCategory;

	get isSystemUser(): boolean {
		return this.notification.fromUser === null;
	}
}
