
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AppLanguage } from '../enums';

@Pipe({
	name: 'language',
	standalone: true
})
export class LanguagePipe implements PipeTransform {

	constructor(private translateService: TranslateService) {}

	transform(value: AppLanguage, isShort = false): string {
		const shortKey = isShort ? '.short' : '';
		return this.translateService.instant('App.Languages.' + value + shortKey);
	}
}
