import { setError, setLoaded, setLoading, withCallState, ISearchItem, ISearchResultsByCategory, ISearchViewMore } from '@aston/foundation';
import { moreResultsLike, orderSearchResults } from '@aston/multi-search-input';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { EMPTY, Observable, catchError, combineLatest, map, of, switchMap, tap } from 'rxjs';
import { Injector, inject } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { accountingSearchProvider } from '../../../accounting-module/services/accounting-documents.search-provider';
import { superDebtorSearchProvider } from '../../../debtors-module/services/super-debtors.search-provider';

const providers = [
	superDebtorSearchProvider,
	accountingSearchProvider,
];

export const initialState = {
	results: {} as ISearchResultsByCategory,
	sortedResultsWithMore: [] as (ISearchItem|ISearchViewMore)[],
	term: '',
}

export const TopBarSearchStore = signalStore(
	withState(initialState),
	withCallState(),
	withMethods(store => {
		const fatStore = inject(Store);
		const injector = inject(Injector);
		const i18n = inject(TranslateService);

		const searchEverything = (term: string) => combineLatest(
			providers.reduce((acc, p) => ({
				...acc,
				...p.search(injector, term)
			}),
			{})
		) as Observable<ISearchResultsByCategory>

		return {

			search(term: string) {
				if ((!term || term.length < 3)) return;
				of(term).pipe(
					tap(_ => patchState(store, { term, ...setLoading() })),
					switchMap(searchEverything),
					map(results => ({ term, results,
						sortedResultsWithMore: orderSearchResults(
							results,
							category => moreResultsLike(i18n,  term, category)
						),
					})),
					tap(patch => patchState(store, patch)),
					tap(_ => patchState(store, setLoaded())),
					catchError(e => {
						console.error(e);
						patchState(store, setError(e))
						return EMPTY
					}),
				)
				.subscribe()
			},

			select(item: ISearchItem | ISearchViewMore) {
				if (!item) return;
				patchState(store, setLoaded());

				providers
					.reduce((acc, p) => acc.concat(p.handle(injector, item)), [] as Action[])
					.forEach(a => fatStore.dispatch(a));
			},
		};
	})
);

export type TopBarSearchStore = InstanceType<typeof TopBarSearchStore>;
