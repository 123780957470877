import { ISearchItem } from '@aston/foundation';
import { searchForCategoryProvider } from '@aston/multi-search-input';
import * as Routes from 'apps/middle/src/app/routes-definitions';
import { Injector } from '@angular/core';

import { SearchResultCategory } from '../../shared-module/enums';
import { AccountingListStore } from '../stores';
import * as AppStoreActions from '../../root-store/app-store/actions';

import { AccountingDocumentsService } from './accounting-documents.service';
import { AccountingListFilterSpecService } from './accounting-list-filter-spec.service';

export const accountingSearchProvider = searchForCategoryProvider(
	SearchResultCategory.Accounting,
	Routes.getAccountingListFullPath(),
	(item: ISearchItem) => Routes.getAccountingDetailFullPath(item.value),
	AppStoreActions,
	(injector: Injector) => injector.get(AccountingListStore).provideSearchActions(),
	AccountingDocumentsService,
	AccountingListFilterSpecService,
).withMoreInitActions(injector => [AppStoreActions.Execute(() => injector.get(AccountingListStore).setShowCompleted(true))]);
