import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, inject } from '@angular/core';
import { ISearchItem, ISearchResults, ISearchResultsByCategory, ISearchViewMore } from '@aston/foundation';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { Subject } from 'rxjs';

@Component({
	selector: 'multi-search-input',
	templateUrl: './multi-search-input.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgSelectModule, FormsModule, TranslateModule, AsyncPipe]
})
export class MultiSearchInputComponent {
	private translateService = inject(TranslateService);
	protected searchGroupByFn = (item: { category: string }) => item.category;
	protected searchInput$ = new Subject<string>();
	protected searchInputModel = '';

	@ViewChild('selector') selector: NgSelectComponent | undefined;

	@Input() results: (ISearchItem|ISearchViewMore)[] = [];
	@Input() groups: ISearchResultsByCategory = {} as ISearchResultsByCategory;

	@Output() search = this.searchInput$.asObservable();
	@Output() pick = new EventEmitter<ISearchItem>();

	protected searchGroupName = (groupKey: string) => {
		const name = this.translateService.instant('TopBar.Search.Group.' + groupKey);
		const group: ISearchResults = this.groups[groupKey];
		return { name, total: group?.totalItemCount || 0 };
	}

	protected onSelect(item: ISearchItem) {
		this.pick.emit(item);
		this.searchInputModel = '';
		this.selector?.handleClearClick();
	}
}
