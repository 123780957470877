import {
	MissingTranslationHandler,
	MissingTranslationHandlerParams,
} from '@ngx-translate/core';

export class AppMissingTranslationHandler implements MissingTranslationHandler {
	handle(
		params: MissingTranslationHandlerParams & {
			interpolateParams?: unknown & { failSilently: boolean };
		},
	) {
		if (params.interpolateParams && params.interpolateParams.failSilently)
			return false;
		const message = '🚫 Missing translation for : ' + params.key + ' 🚫';
		console.warn(message);
		return message;
	}
}
