import { LoaderSpinnerComponent } from '@aston/foundation/components';
import { Component, OnInit } from '@angular/core';

import { LoginPageComponent } from '../login-page/login-page.component';

@Component({
	selector: 'login-callback-page',
	templateUrl: '../login-page/login-page.component.html',
	standalone: true,
	imports: [LoaderSpinnerComponent]

})
export class LoginCallbackPageComponent extends LoginPageComponent implements OnInit {
	// eslint-disable-next-line @angular-eslint/no-empty-lifecycle-method
	ngOnInit() {
		// nothing here
	}
}
