import { Component, Output, EventEmitter, Input, inject } from '@angular/core';
import { AppLanguage, ActionType, ISearchItem, UnsubscriberClass, PageHeaderComponent, LanguagePipe } from '@aston/foundation';
import { environment } from 'apps/middle/src/environments/environment';
import { IAction } from '@aston/foundation';
import { Store } from '@ngrx/store';
import { AppStoreActions, AppStoreSelectors } from 'apps/middle/src/app/root-store/app-store';
import { UserClearanceLevel } from 'apps/middle/src/app/authentication-module/enums';
import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { FeatureFlagDirective } from '@aston/feature-flags';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiSearchInputComponent } from '@aston/multi-search-input';

import { UserNotificationsStore } from '../../../notifications-module/stores';
import { UserAvatarComponent } from '../user-avatar/user-avatar.component';
import { WorkflowSchedulingStore } from '../../../admin-module/store';
import { IUserAvatar } from '../../models';

import { TopBarSearchStore } from './top-bar.search.store';

@Component({
	selector: '[layout-top-bar]',
	templateUrl: './top-bar.component.html',
	standalone: true,
	imports: [FeatureFlagDirective,
		TranslateModule,
		NgbDropdownModule,
		PageHeaderComponent,
		MultiSearchInputComponent,
		UserAvatarComponent,
		AsyncPipe,
		UpperCasePipe,
		LanguagePipe,
		NgbTooltipModule
	],
	providers: [TopBarSearchStore]
})
export class TopBarComponent extends UnsubscriberClass {
	private store = inject(Store);
	private searchStore = inject(TopBarSearchStore);
	private workflowSchedulingStore = inject(WorkflowSchedulingStore);
	protected notifications = inject(UserNotificationsStore);

	@Input() currentLanguage: AppLanguage;
	@Input() user: IUserAvatar;
	@Input() tenantName: string;
	@Input() tenantLogo: string;
	@Input() tenantConsoleId: number;

	@Output() selectLanguage: EventEmitter<AppLanguage> = new EventEmitter();
	@Output() logout: EventEmitter<void> = new EventEmitter();
	@Output() action: EventEmitter<IAction> = new EventEmitter();

	fr = AppLanguage.FR;
	en = AppLanguage.EN;
	hasTranslations = environment.translations.enabled;
	path = window.location.href;

	config$ = this.store.select(AppStoreSelectors.selectConfig);
	operator$ = this.store.select(AppStoreSelectors.selectCurrentUser);

	searchGroups = this.searchStore.results;
	searchResultsWithMore = this.searchStore.sortedResultsWithMore;

	setLanguage(language: AppLanguage) {
		this.selectLanguage.emit(language);
	}

	userLogout() {
		this.logout.emit();
	}

	leavePlatform() {
		this.store.dispatch(AppStoreActions.NavigateToConsole({path: '/dashboard/subsidiaries'}))
	}

	editMyInformations() {
		this.store.dispatch(AppStoreActions.NavigateToGate({path: '/Account/edit?ReturnUrl=' + encodeURIComponent(this.path)}))
	}

	toggleNotificationsPanel() {
		this.action.emit({type: ActionType.TOGGLE, value: 'notifications'});
	}

	toggleThemesPanel() {
		this.action.emit({type: ActionType.TOGGLE, value: 'themes'});
	}

	userMenuOpened() {
		this.action.emit({type: ActionType.OPEN, value: 'user'});
	}

	openHelp() {
		this.store.dispatch(AppStoreActions.NavigateToHelpPage())
	}

	get canUpdatePlatform$(){
		return this.store.select(AppStoreSelectors.selectClearsLevel(UserClearanceLevel.UpdatePlatform));
	}

	updatePlatform(){
		this.store.dispatch(AppStoreActions.OpenConfirmationModal(
			{textsKey: 'Modal.UpdatePlatform.'},
			AppStoreActions.Execute(() => this.workflowSchedulingStore.startConfig())));
	}

	search(term: string) {
		this.searchStore.search(term);
	}

	pickSearchResult(item: ISearchItem) {
		this.searchStore.select(item);
	}
}
