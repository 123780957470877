
import { Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';

import { IBookmark } from '../../models/bookmark.model';

@Component({
	selector: 'bookmark',
	templateUrl: './bookmark.component.html',
	standalone: true,
	imports: [TranslateModule, RouterLink]
})
export class BookmarkComponent {
	bookmark = input.required<IBookmark>();
	remove = output<IBookmark>();
}
