<div class="notifications-head">
	<h2>
		{{ 'TopBar.Notifications.Title' | translate }}
		<br><small><a (click)="store.closePanel()" [routerLink]="notificationsPagePath" translate="TopBar.Notifications.All"></a></small>
	</h2>
	<btn-group-switch
		(valueChange)="store.changeMode($event ? NotificationsListMode.Unread : NotificationsListMode.All)"
		[selected]="store.showUnreadOnly()"
		[buttons]="[
			{label: 'CommonTexts.All', value: false },
			{label: 'CommonTexts.Unread', value: true }
		]"
	/>
</div>

<notifications-list
	[notifications]="store.notificationsForSidebar()"
	[loader]="loader"
	(action)="store.open($event.value)"
/>
