import { InjectionToken, Provider, Signal } from '@angular/core';
import { Observable } from 'rxjs';

import { AppBaseConfiguration } from '../../app-base.configuration';

export type FullDebugBarConfig = {
	configuration: AppBaseConfiguration,
	errors: { events$: Observable<any> },
	scrollService: { scrolled: Signal<number> },
	defaultFeatureFlags: string[]
	featureFlagsService: {
		toggleFeature: (flag: string) => void,
		isFeatureEnabled: (flag: string) => boolean,
	}
	tenant$: Observable<{ id: number, name: string }>
	sessionId$: Observable<string>
	inactivityService: {
		inGracefulPeriod$: Observable<boolean>,
		inactivityCountdown$: Observable<number>,
	},
	auth: {
		getAccessTokenExpiration: () => Observable<number>,
		renewTimeBeforeTokenExpires: () => Observable<number>,
	},
	supportImpersonation?: () => void,
	websocket: {
		hubId$: Observable<string>,
		connected$: Observable<boolean>,
	}
}

export type DebugBarConfig = Partial<FullDebugBarConfig>

export const DEBUG_BAR_CONFIG: InjectionToken<DebugBarConfig> = new InjectionToken('debug bar config');

export function provideDebugBarService(config?: DebugBarConfig): Provider[] {
	return [
		{
			provide: DEBUG_BAR_CONFIG,
			useValue: config,
		}
	]
}
