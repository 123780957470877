
import { Store, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilesManagerStoreModule, FilesManagerAppConnector } from '@aston/files-manager';
import { UserPrefsStoreModule, UserPrefsAppConnector, pageSizesMetaReducer } from '@aston/user-prefs';

import { UserService } from '../user-module/services';

import { AppStoreActions, AppStoreModule, AppStoreSelectors } from './app-store';
import { DunningActionDetailStoreModule } from './dunning-action-detail-store';
import { DunningScenarioDetailStoreModule } from './dunning-scenario-detail-store';
import { DunningTemplateDetailStoreModule } from './dunning-template-detail-store';
import { logoutMetaReducer } from './app-store/reducer';
import { SuperDebtorPageStoreModule } from './super-debtor-page-store';


export function stateSetter(reducer) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	return function (state: any, action: any) {
		const NGRX_STATE = '___NGRX_STATE___';
		const newState = reducer(state, action);
		if (action.type !== '@ngrx/store/init') {
			return window[NGRX_STATE] = newState;
		} else {
			if (window[NGRX_STATE]) {
				return window[NGRX_STATE];
			}
			return newState;
		}
	};
  }

function debugMetaReducer(reducer: CallableFunction) {
	return function (previs: any, action: any) {
		const state = reducer(previs, action)

		// if (
		// 	previs?.dunningActionDetail?.dunningAction?.entity
		//  && !state?.dunningActionDetail?.dunningAction?.entity
		// ) {
		// 	console.log(`action %o cleared %o`, action, 'dunningActionDetail.dunningAction.entity')
		// }

		return state
	}
}

// The metareducers option allow to reset the whole internal state on logout
// see https://ngrx.io/guide/store/metareducers
// export const MetareducedStoreModule = StoreModule.forRoot({}, { metaReducers: [logout, throwOnUndefined] });
export const MetareducedStoreModule = StoreModule.forRoot({}, {
	metaReducers: [stateSetter, logoutMetaReducer, pageSizesMetaReducer],
	runtimeChecks: {
		// because we are savages
		strictStateImmutability: false,
		strictActionImmutability: false,
		strictStateSerializability: false,
		strictActionSerializability: false,
	},
});

export const userPrefsAppConnector = (store: Store, us: UserService) => ({
	currentPreferences: store.select(AppStoreSelectors.selectCurrentUserPrefs),
	updatePreferences: prefs => us.savePreferences(prefs),
	updateFilters: filters => us.saveUserFilters(filters)
})

export const filesManagerAppConnector = (store: Store) => ({
	download: document => store.dispatch(AppStoreActions.DownloadDocument({ document })),
	preview: document => store.dispatch(AppStoreActions.PreviewDocument({ document })),
})

@NgModule({
	declarations: [],
	imports: [
		AppStoreModule,
		CommonModule,
		DunningActionDetailStoreModule,
		DunningScenarioDetailStoreModule,
		DunningTemplateDetailStoreModule,
		EffectsModule.forRoot([]),
		FilesManagerStoreModule.forRoot({
			app: {
				provide: FilesManagerAppConnector,
				useFactory: filesManagerAppConnector,
				deps: [Store]
			}
		}),
		MetareducedStoreModule,
		SuperDebtorPageStoreModule,
		UserPrefsStoreModule.forRoot({
			app: {
				provide: UserPrefsAppConnector,
				useFactory: userPrefsAppConnector,
				deps: [Store, UserService]
			}
		}),
	]
})
export class RootStoreModule { }
