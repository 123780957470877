import { compose, dark, light } from "@aston/themes"

const theme = {
    name: 'Default',
    id: 'default',
    colors:{
        main: '#efefef',
        primary: '#55e',
        secondary: '#aae'
    },
    business: {
        dunningActionTypeColors: {
            0: compose('#f3755b', dark),
            1: compose('#ffb95c', dark),
            2: compose('#81addd', dark),
            3: compose('#9ed0b4', dark),
            4: compose('#005f73', light),
            5: compose('#0a9396', dark),
            6: compose('#ee9b00', light),
            7: compose('#e9d8a8', dark),
            8: compose('#005f75', light),
        },
        kpisColors: {
            10: compose('#1558A6', light),
        }
    }
}
export default theme
