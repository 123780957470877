import { BtnGroupSwitchComponent, IAction } from '@aston/foundation';
import { Component, inject, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';

import { NotificationsListComponent } from '../notifications-list/notifications-list.component';
import { UserNotificationsStore } from '../../../notifications-module/stores';
import { NotificationsListMode } from '../../../notifications-module/enums';
import * as RoutesDefinitions from '../../../routes-definitions';

@Component({
	selector: 'notifications-panel',
	templateUrl: './notifications-panel.component.html',
	standalone: true,
	imports: [TranslateModule, RouterLink, NotificationsListComponent, BtnGroupSwitchComponent, AsyncPipe]
})
export class NotificationsPanelComponent {
	store = inject(UserNotificationsStore);

	action = output<IAction>();

	NotificationsListMode = NotificationsListMode;
	notificationsPagePath = RoutesDefinitions.getNotificationsDashboardFullPath();

	loader = this.store.notificationsLoader();
}
