import { ISearchItem } from '@aston/foundation';
import { searchForCategoryProvider } from '@aston/multi-search-input';
import * as Routes from 'apps/middle/src/app/routes-definitions';
import { Injector } from '@angular/core';

import { SearchResultCategory } from '../../shared-module/enums';
import * as AppStoreActions from '../../root-store/app-store/actions';
import { SuperDebtorsListStore } from '../stores';

import { SuperDebtorsListFilterSpecService } from './super-debtors-list-filter-spec.service';
import { SuperDebtorsListService } from './super-debtors-list.service';

export const superDebtorSearchProvider = searchForCategoryProvider(
	SearchResultCategory.Superdebtor,
	Routes.getSuperDebtorsListFullPath(),
	(item: ISearchItem) => Routes.getSuperDebtorDetailFullPath(item.value),
	AppStoreActions,
	(injector: Injector) => injector.get(SuperDebtorsListStore).provideSearchActions(),
	SuperDebtorsListService,
	SuperDebtorsListFilterSpecService,
);
